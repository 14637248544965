import Typography from "@mui/material/Typography";
import React from "react";
import { useNavigate } from "react-router-dom";
import { NavButton } from "../../styles/components";
import styles from "./Header.module.css";

const Header = () => {
  const history = useNavigate();
  const homeRedirectHandler = () => {
    history("/");
  };

  const usersRedirectHandler = () => {
    history("/users");
  };

  return (
    <header className={styles.header}>
      <div className={styles.headerLogoImage}>
        <Typography
          onClick={homeRedirectHandler}
          variant="h3"
          className={styles.headerText}
        >
          Starter App
        </Typography>
      </div>
      <div>
        <NavButton
          className={styles.headerButton}
          onClick={homeRedirectHandler}
        >
          Home
        </NavButton>
        <NavButton
          className={styles.headerButton}
          onClick={usersRedirectHandler}
        >
          Users
        </NavButton>
      </div>
    </header>
  );
};

export default Header;
