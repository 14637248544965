import React, { useState, Fragment } from "react";
import Display from "../components/Display/Display";
import Footer from "../components/UI/Footer";
import Header from "../components/UI/Header";
import styles from "../styles/flexBox.module.css";

const UsersPage = (props) => {
  const [checkedEntities, setCheckedEntities] = useState([]);
  // setCheckedEntities = (newCheckedEntity) => ({
  //   checkedEntities: [...checkedEntities, newCheckedEntity],
  // });

  return (
    <Fragment>
      <div className={styles.flex}>
        <Header />
        <Display
          setCheckedEntities={setCheckedEntities}
          checkedEntities={checkedEntities}
        />
        <Footer />
      </div>
    </Fragment>
  );
};

export default UsersPage;
