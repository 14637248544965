import { BrowserRouter, Routes, Route } from "react-router-dom";
import NotFound from "./containers/NotFound";
import HomePage from "./containers/HomePage";
import UsersPage from "./containers/UsersPage.js";
import { createBrowserHistory } from "history";

export const history = createBrowserHistory();

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route path="/" element={<HomePage />} />
        <Route path="/users" element={<UsersPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
