import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import React, { useEffect } from "react";
import styles from "./NotFoundCard.module.css";

const NotFoundCard = () => {
  useEffect(() => {
    document.title = "Omnis - Not Found";
  }, []);

  return (
    <Card className={styles.notFound}>
      <CardContent>
        <Typography variant="h2">404 Not Found</Typography>
      </CardContent>
    </Card>
  );
};

export default NotFoundCard;
