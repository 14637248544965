import { styled } from "@mui/material";
import { Button } from "@mui/material";

export const GoButton = styled(Button)({
  backgroundColor: "black",
  border: 0,
  boxShadow: "1px 2px rgb(55, 55, 55)",
  borderRadius: "30px",
  color: "white",
  cursor: "pointer",
  fontSize: "16px",
  verticalAlign: "bottom",
});

export const NavButton = styled(Button)({
  borderLeft: "1px solid rgb(225, 225, 225)",
  cursor: "pointer",
  float: "right",
  fontSize: "16px",
  padding: "0 2em",
  color: "black",
});

export const SubscribeButton = styled(Button)({
  cursor: "pointer",
  border: "1px solid rgb(225,225,225)",
  borderTop: "none",
  borderRadius: "0",
});

export const SubscribedButton = styled(Button)({
  cursor: "pointer",
  border: "1px solid rgb(225,225,225)",
  borderTop: "none",
  borderRadius: "0",
  backgroundColor: "rgb(155,155,155)",
});

export const ShowRecordsButton = styled(Button)({
  cursor: "pointer",
  border: "1px solid rgb(225,225,225)",
  borderTop: "none",
  borderRadius: "0",
  backgroundColor: "rgb(225,225,225)",
  marginLeft: "5px",
});

export const HideRecordsButton = styled(Button)({
  cursor: "pointer",
  border: "1px solid rgb(225,225,225)",
  borderTop: "none",
  borderRadius: "0",
  backgroundColor: "rgb(155,155,155)",
  marginLeft: "5px",
});

export const ShowHistoryButton = styled(Button)({
  cursor: "pointer",
  border: "1px solid rgb(225,225,225)",
  borderTop: "none",
  borderRadius: "0",
  backgroundColor: "rgb(225,225,225)",
  marginLeft: "5px",
});

export const HideHistoryButton = styled(Button)({
  cursor: "pointer",
  border: "1px solid rgb(225,225,225)",
  borderTop: "none",
  borderRadius: "0",
  backgroundColor: "rgb(155,155,155)",
  marginLeft: "5px",
});

export const TrackButton = styled(Button)({
  backgroundColor: "black",
  border: 0,
  boxShadow: "1px 2px rgb(55, 55, 55)",
  borderRadius: "30px",
  color: "white",
  cursor: "pointer",
  fontSize: "16px",
  verticalAlign: "bottom",
  width: "10%",
  margin: "0 auto",
});
