import React, { useEffect, useHistory } from "react";
import { Card, Divider, Typography } from "@mui/material";
import styles from "./Display.module.css";

const Display = ({ checkedEntities }) => {
  //   const history = useHistory();
  //   useEffect(() => {
  //     document.title = "Homepage";
  //   }, []);

  const urlPath = "www.bantemine.co.uk/api";
  // config.SERVER_URL +
  // `/entities/${i.entityId}?featureMode=WITH_DUPLICATES&withFeatureStats=false&withInternalFeatures=false&forceMinimal=false&withRelated=PARTIAL&withRaw=false`;
  //   const fetchData = async () => {
  //     fetch(urlPath)
  //       .then((response) => response.json())
  //       .then((data) => {
  //         console.log(data.data);
  //         setCheckedEntities((checkedEntities) => [
  //           ...checkedEntities,
  //           data.data,
  //         ]);
  //       });
  //   };

  return (
    <div>
      <Card className={styles.subCard}>
        <Typography variant="h3" className={styles.subHeader}>
          Users
        </Typography>
        <Divider />
      </Card>
    </div>
  );
};

export default Display;
