import React, { Fragment } from "react";
import Footer from "../components/UI/Footer";
import Header from "../components/UI/Header";
import NotFoundCard from "../components/ErrorPage/NotFoundCard";
import styles from "../styles/flexBox.module.css";

const NotFound = () => {
  return (
    <Fragment>
      <div className={styles.flex}>
        <Header />
        <NotFoundCard />
        <Footer />
      </div>
    </Fragment>
  );
};

export default NotFound;
