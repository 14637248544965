import React from "react";
import styles from "./Footer.module.css";
import logo from "../../assets/ibm-logo.png";

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <img className={styles.Logo} src={logo} alt="IBM Logo Black" />
    </footer>
  );
};

export default Footer;
